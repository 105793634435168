function gameLoad() {

	if ( $('#iframe').length === 0 || $('#iframe').length === undefined)
		return false;

	var iframeBlock = $('.iframe-block');
	var iframeBackground = $('#iframe_background');
	var iframeWidth = iframeBackground.width();
	var iframeHeight = iframeBackground.height();

    /*if (window.devicePixelRatio != undefined) {
        iframeWidth = iframeWidth / window.devicePixelRatio;
        iframeHeight = iframeHeight / window.devicePixelRatio;
    }*/

	// Fake HTML5 loader options:
	var iframeLoader = $('.iframe_loader');
	var loaderTime = 5500;

	var mobile = $('.iframe-block').data('mobile');
	var fullscreenBut = $('.button-fullscreen');

	var params = {
		gameid:  iframeBlock.data('name'),
		country: 'AUTO',
		width: iframeWidth,
		height: iframeHeight,
		//iframe_only:true,
	};

	$.ajax({
		type: 'POST', // sending as post
		url: gamesUrl + 'game_json', // games Handler
		dataType: "json",

		xhrFields: {
		    withCredentials: false
		},

		data: params, // дaнныe для oтпрaвки
		success: function(data) {

			var errorIframe = 0;
			
			if ($('#iframe.verify_age').length > 0) {
    			errorIframe = 2;
    			loadAgeError(mobile);
			}

            setTimeout(function() {
                Cookies.set('played', Cookies.get('played') ? parseInt(Cookies.get('played')) + 1 : 1, {expires: 4});            
            }, 5000);
			
			if (mobile) {
				//data.mobile.isMobile=true;
				$('#iframe').addClass('mobile');
				errorIframe = gameLoadMobile(data.mobile, iframeBlock, fullscreenBut, iframeLoader, iframeBackground, loaderTime);
				//data.no_fullscreen = false;
				
				//errorIframe = gameLoadDesktop(data.mobile, iframeBlock, fullscreenBut, iframeLoader, iframeBackground, loaderTime);
				
			} else {
				//data.isMobile=false;
				errorIframe = gameLoadDesktop(data, iframeBlock, fullscreenBut, iframeLoader, iframeBackground, loaderTime);
			}

			

			if (errorIframe == 1) {
				loadIframeError(mobile);
			}


		}

	}).fail(function() {			
		loadIframeError(mobile);
	});	
}

function gameLoadMobile(data, iframeBlock, fullscreenBut, iframeLoader, iframeBackground, loaderTime) {
	var error = 0;

	if (data.frame === undefined) {
		error = 1;
		return error;		
	}

	$('input[name="demo_id"]').val(data.id);

    iframeBlock.attr('game-id', data.id);
	iframeBlock.find(".btn_startgame").removeClass("game_loading");
	//iframeBlock.append('<a href="#" class="btn_startgame"></a>');
	
	//$(".hr").first().append('<a href="' + data.direct_url + '" target="_blank">GAME START</a>');
	//if( typeof data.for_embed != 'undefined' && data.for_embed==false && isMobileSafari()) {
/*
	if( isMobileSafari()) {
			$(".btn_startgame").append('<a class="directlink" href="'+data.direct_url+'" target=_blank rel="nofollow noopener"></a>');
			return false;
	}
*/
		
	$('.btn_startgame').on('click', function() {
		
		if( typeof data.for_embed != 'undefined' && data.for_embed==false) {
			window.open(data.direct_url, '_blank');
			return false;
		}
		
		$('.button-fullscreen').click();
		if($('#iframe').hasClass('launched')){
			return false;
		}
		iframeBackground.html(data.frame);
		if(typeof lazyload == 'function') lazyload(iframeBackground.find(".lazyload, img[data-lazy]"));
		gameRatio=eval(data.dimensions.replace(":","/"));
		iframeSize(gameRatio);
		$(window).bind("resize orientationchange",function(){
			iframeSize(gameRatio);
			setTimeout(iframeSize, 35, gameRatio);
			setTimeout(iframeSize, 250, gameRatio);
		});

		//$(this).hide();
//$(".wrapper").height(iframeBackground.height()+50);
		$('#iframe').addClass('launched');
		
		/*$('.wrapper').bind("touchmove",function(e){
			//$("body, html").scrollTop(0);
			e.preventDefault();
			e.stopPropagation();
			return false;
		});*/
		iframeLoader.show();

		animateLoader();
		$("html, body").stop().animate({scrollTop:window.scrollTop+120},400);
		setTimeout(function(){
			iframeBlock.find(".iframe_loader").hide();
			stopAnimateLoader();
			//$("html, body").stop().animate({scrollTop:window.scrollTop+120},400);
			setTimeout(makeFrameBonusList,2000);
		}, loaderTime);

	});
	
	/*var error = 0;

	if (data.mobile === undefined || data.mobile.direct_url === undefined) {
		error = 1;
		return error;		
	}

    iframeBlock.attr('game-id', data.mobile.id);
    iframeBlock.find(".game_loading").remove();
	iframeBlock.append('<a href="' + data.mobile.direct_url + '" target="_blank" class="btn_startgame"><span class="button button-play"><img class="icon" src="//cdn.'+secondLvlDomain+'/template/X/img/icons/play.svg" alt=""> Juega gratis</span></a>');

	$('.btn_startgame').on('click', function() {
		return true;
	});*/

	return error;
}

function gameLoadDesktop(data, iframeBlock, fullscreenBut, iframeLoader, iframeBackground, loaderTime) {

	var error = 0;

	if (data.frame === undefined) {
		error = 1;
		return error;		
	}

	if (data.no_fullscreen == false)
		fullscreenBut.show();

	$('input[name="demo_id"]').val(data.id);

    iframeBlock.attr('game-id', data.id);
	iframeBlock.find(".btn_startgame").removeClass("game_loading");
	//iframeBlock.append('<a href="#" class="btn_startgame"></a>');

	$('.btn_startgame').on('click', function() {
		
		iframeBackground.html(data.frame);
		if(typeof lazyload == 'function') lazyload(iframeBackground.find(".lazyload, img[data-lazy]"));
		gameRatio=eval(data.dimensions.replace(":","/"));
		
		$(window).resize(function(){
			iframeSize(gameRatio);
		});
		$('#iframe').addClass('launched');
		$(this).hide();
		iframeSize(gameRatio);

		$('#iframe').addClass('launched');
		iframeLoader.show();

		animateLoader();

		setTimeout(function(){
			iframeBlock.hide();
			stopAnimateLoader();
			setTimeout(makeFrameBonusList,2000);
		}, loaderTime);

		return false;
	});
	
	return error;
	
}
function isMobileSafari() {
	var A=navigator.userAgent;
    return !A.match(/(CriOS)/) && A.match(/(iPod|iPhone|iPad)/) && A.match(/Safari/);
}


var frameBonusIndex=0;
function makeFrameBonusList(){
	var putHere=$(".frame-promo");
	var getfrom=$(".bonuses-table-v2 .bt-body .bt-row");
	if(putHere.find(">div").length){
		$(".frame-name").hide();
		putHere.removeClass('hidden').find(">div").hide().eq(frameBonusIndex).show(300);
		setInterval(slideFrameBonus, 9000);
		return;
	}
	
	if(getfrom.length<1) return;
	if(putHere.length)$(".frame-name").hide();
	getfrom.each(function(){
		putHere.append('<div class="one"><a href="'+$(this).find(".button-solid").attr('href')+'" rel="noopener nofollow" target=_blank>'+translate.bonus+' <span class="white-color"><b>'+$(this).find(".casino-name a").text()+'</b> - <b>'+$(this).find(".casino-bonus span").html()+'</b></span></a></div>');
	});
	putHere.removeClass('hidden').find(">div").hide().eq(frameBonusIndex).show(300);
	setInterval(slideFrameBonus, 12000);
}
function slideFrameBonus(){
	var el=$(".frame-promo>div");
	el.eq(frameBonusIndex).fadeOut(100,function(){
		frameBonusIndex++;
		if(frameBonusIndex>=el.length) frameBonusIndex=0;
		el.eq(frameBonusIndex).fadeIn(300).delay(6000).fadeOut(400);
	});
}

function loadAgeError(mobile){
	if($('#iframe').length > 0){
    	
//    	console.log('loadAgeError');
    	
		var iframeBlock = $('.iframe-block');
		var iframeBackground = $('.iframe_background');
		iframeBackground.show();
		$.ajax({
			type: 'POST', // oтпрaвляeм в POST фoрмaтe
			url: apiPath + 'post/' + options['context'] + '/' + (mobile ? 'mobile' : 'desktop') + '/game_age_error', // путь дo oбрaбoтчикa

			xhrFields: {
				withCredentials: true
			},

			success: function(data){
				if(data){
					iframeBackground.append(data);
					iframeBlock.hide();
					if(typeof lazyload == 'function') lazyload();
				}
			}
		});
	}
}

