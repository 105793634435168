(function(){
	

	var m=$(".nav-search"),
	body=m.find(".search-body"),
	input=body.find(".input"),
	result=body.find(".result"),
	timeoutID=0,
	text='',
	reset=m.find(".reset"),
	darkcover=$("#darkcover");
	m.bind("click",function(e){
		e.stopPropagation();
		body.removeClass('hidden');
		darkcover.removeClass('hidden');
		input.focus();
	});
	input.bind("keydown change",function(){
		if(timeoutID)clearTimeout(timeoutID);
		timeoutID=setTimeout(searchRequest,220,body,input,result);
	});
	if(typeof Cookies !== "undefined") {
		var last=Cookies.get('search_for');
		if(last){
			input.val(last);
		}
	}
	reset.click(function(e){
		e.stopPropagation();
		body.addClass('hidden');
		darkcover.addClass('hidden');
		return false;
	});
	darkcover.click(function(e){
		e.stopPropagation();
		body.addClass('hidden');
		darkcover.addClass('hidden');
		return false;
	});
	input.bind("focusout",function(){
		if(typeof Cookies !== "undefined"&&searchText) Cookies.set('search_for', searchText,{ expires: 30, path: '/' });
		if (typeof _paq != "undefined" && lastTracked!=searchText) {
			_paq.push([ 'trackSiteSearch', searchText ]);
		}
	});

var searchText='';
var lastTracked='';
function searchRequest(body,input,result){
	console.log('search = '+input.val());
	var newText=$.trim(input.val());
	if(newText.length<=1){
		result.html('');
		body.removeClass('withresult');
		searchText='';
		return;	
	}
	if(searchText==newText) {
		return;
	}
	if(newText.indexOf(searchText)==-1 && lastTracked!=searchText){
		if (typeof _paq != "undefined") {
			_paq.push([ 'trackSiteSearch', searchText ]);
			lastTracked=searchText;
		}
	}
	searchText=newText;
	
	$.ajax("/search?search_query="+searchText)
		.done(function(data){
			result.html(data);
			body.addClass('withresult');
		}).fail(function() {
		    console.log('Search Fail!');
		});
	if(typeof Cookies !== "undefined"&&searchText) Cookies.set('search_for', searchText,{ expires: 30, path: '/' });
}

})()