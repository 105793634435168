// var inURL=window.location.hostname;
	// I dont want to think moment:
	// Idea is to keep valid slice-2 string   
/*
	inURL = inURL.replace('com.ua', 'com///ua');
	inURL = inURL.replace('org.ua', 'org///ua');
*/
var secondLvlDomain=window.location.hostname;

var apiPath = window.location.protocol+'//'+'api.'+secondLvlDomain+'/';
var apiUrl = apiPath + '/all-ajax.json';
var gamesUrl = 'https://beta.slotsplay.app/';
var gameRatio=1.3333;


(function(jQuery){
	jQuery.fn.shuffle = function(){
		var allElems = this.get();
		var getRandom = function(max){
			return Math.floor(Math.random() * max);
		};
		var shuffled = jQuery.map(allElems, function(){
			var random = getRandom(allElems.length),
			randEl = jQuery(allElems[random]).clone(true)[0];
			allElems.splice(random, 1);
			return randEl;
		});
 
		this.each(function(i){
			jQuery(this).replaceWith(jQuery(shuffled[i]));
		});
 
		return jQuery(shuffled);
	};
})(jQuery);
(function ($) {
	$.fn.serializeFormJSON = function () {

		var o = {};
		var a = this.serializeArray();
		$.each(a, function () {
			if (o[this.name]) {
				if (!o[this.name].push) {
					o[this.name] = [o[this.name]];
				}
				o[this.name].push(this.value || '');
			} else {
				o[this.name] = this.value || '';
			}
		});
		return o;
	};
})(jQuery);
function removeHash()	{
	var uri = window.location.toString();
	if (uri.indexOf("#") > 0) {
		var clean_uri = uri.substring(0, uri.indexOf("#"));
		window.history.replaceState({}, document.title, clean_uri);
	}
}
function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}
function getParameterByName(name, url, hash) {
	if (!url) return null;
	name = name.replace(/[\[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, " "));
}
function shuffle(a) {
	var j, x, i;
	for (i = a.length; i; i--) {
		j = Math.floor(Math.random() * i);
		x = a[i - 1];
		a[i - 1] = a[j];
		a[j] = x;
	}
}
function closeAllModals(){
	if($('.modal_block.active').length > 0){
		$('body').removeClass('overhidden');
		$('.modal_block.active').removeClass('active').parent('.modal').removeClass('show');
		$('.modal-button.active').removeClass('active');
	}
}
function modalButtons(){
	if($('.modal').length > 0 && $('.modal_block').length > 0 && $('.modal-button').length > 0){
		$('.modal-button').on('click', function(){
			var block = $(this).attr('data-modal');
			var me = $(this);
			closeAllModals();
			if($('#'+block).length > 0){
				/*
if($(this).hasClass('active')){
					$('#'+block).removeClass('active').parent('.modal').removeClass('show');
					$(this).removeClass('active');
					$('body').removeClass('overhidden');
				}	else	{
					$('#'+block).addClass('active').parent('.modal').addClass('show');
					$('#'+block).find('.button-close').addClass('active').off('click').one('click', function(){
						me.removeClass('active');
						$('#'+block).removeClass('active').parent('.modal').removeClass('show');
					});
					$('body').addClass('overhidden');
					$(this).addClass('active');
				}
*/
				$('#'+block).addClass('active').parent('.modal').addClass('show');
				$('#'+block).find('.button-close').addClass('active').off('click').one('click', function(){
					closeAllModals();
					return false;
				});
				$('body').addClass('overhidden');
				$(this).addClass('active');
			}
			return false;
		});
	}
}
/*function rainbowLinks() {
	var a = "c-1 c-2 c-3 c-4 c-5 c-6".split(" "),
		b = a.length - 1,
		c = 0;
	$('.text-block p a, .text-block ul a').each(function(){
		if(c > b)
			c = 0;
		$(this).addClass(a[c]);
		c++;
	});
}
function rainbowTitle() {
	$(".rainbow_title").each(function(a) {
		a = $(this).text().split("");
		for (var c in a)
			a[c] = "<span>" + a[c] + "</span>";
		a = a.join("");
		$(this).html(a);
	});

//$c-1: #249118;
//$c-2: #ffc62b;
//$c-3: #ff4922;
//$c-4: #ff2722;
//$c-5: #ff1f20;
//$c-6: #c2244d;
//$c-7: #1b54d7;
//a = "#fce232 #61ffe9 #ff4c4b #66e335 #ff61f6 #ff982e"
//a = "#249118 #ffc62b #ff4922 #ff2722 #ff1f20 #c2244d #1b54d7".split(" ")

	var a = "#fce232 #61ffe9 #ff4c4b #66e335 #ff61f6 #ff982e".split(" "),
		b = a.length - 1,
		c = 0;
	shuffle(a);
	$(".rainbow_title span").each(function(d) {
		if (" " == $(this).text())
			return !0;
		if(c > b)
			c = 0;
		$(this).css("color", a[c]);
		c++;
	});
}*/
function scrollToTop(){
	if($('.scrollerTop').length > 0) {
		var click = 0;
		var block = $('.scrollerTop');
		block.on('click', function(){
			click = 1;
			$(document).scrollTop(0);
			block.addClass('inprogress').css({opacity: 1, border: 0}).animate({bottom: $(window).height()+50}, 500, function(){
				$(this).css({bottom:'', opacity: '', border: ''}).hide().removeClass('inprogress');
				click = 0;
			});
		});
		var interval = setInterval(function(){
			if(!click){
				if($(document).scrollTop() > $(window).height()){
					if(block.is(":hidden")){
						block.fadeIn(300);
					}
					if(block.is(":visible")){
						if($(document).height() / $(document).scrollTop() < 2){
							block.addClass('phase_2');
						}	else if($(document).height() / $(document).scrollTop() < 3)	{
							block.removeClass('phase_2').addClass('phase_1');
						}	else	{
							block.removeClass('phase_2 phase_1');
						}
					}
					
				}	else	{
					if(block.is(":visible")){
						block.addClass('inprogress').css({opacity: 1, border: 0}).animate({bottom: $(window).height()+50}, 500, function(){
							$(this).css({bottom:'', opacity: '', border: ''}).hide().removeClass('inprogress');
						});
					}
					
				}
			}
			
		}, 1000);
	}
}
function prepareAjax(page, filter, dataInfo, getAll,append){
	append=append?append:0;
	if(!append) filter.find('.shuffle_this').animate({opacity: 0}, 250);
	var paginator=filter.find('.pagination'),
	ajaxInfoEl = filter.find('.ajaxInfo');
	page = parseInt(page) ? parseInt(page) : 1;
	var filterArr=[],sortArr=[],searchArr=[],searchValues=[];
	dataInfo = dataInfo ? dataInfo : ajaxInfoEl.attr('data-info');
	getAll = getAll ? getAll : 0;
	
	filter.find('.widget_buttons_list a.active[data-f-method]').each(function(){
		if($(this).attr('data-f-method').length) filterArr.push($(this).data('f-method'));
	});
	filter.find('.filter_list a.active[data-f-method]').each(function(){
		if($(this).attr('data-f-method').length) filterArr.push($(this).data('f-method'));
	});
	filter.find('.filter_sort.changed').each(function(){
		if($(this).attr('data-f-sort').length) sortArr.push($(this).data('f-sort-type')+'='+$(this).data('f-sort'));
	});
	filter.find('.filter_search').each(function(){
		if($(this).val()){
			searchArr.push($(this).data('f-search-type')+'='+encodeURIComponent($(this).val()));
		}
	});
	if($('.developer-filter').length > 0){
		$('.developer-filter').find('a.active[data-filter]').each(function(){
			if($(this).attr('data-filter').length)filterArr.push($(this).data('filter'));
		});
	}
	var callbackFunct;
	var ajaxInfo = {
			info: dataInfo,
			page: page, 
			filter: filterArr,
			sort: sortArr,
			search: searchArr,
			getAll: getAll
			};
	$.ajax({
		type: 'GET',
		dataType: "json",
		url: apiUrl,
		xhrFields: {
			withCredentials: true
		},
		data: {
			ajaxInfo : ajaxInfo, 
			action: ajaxInfoEl.attr('data-action')
			},
		success: function(data) {
			var output = data;
			var sthis=filter.find('.shuffle_this');
			//console.log('output'+output);
			if(output.status == 'ok' && output.data){
				//console.log(output.f);
				output.p = (output.p != undefined) ? output.p : '';
				output.f = (output.f != undefined) ? output.f : '';
				
				if(append) sthis.append(output.data); else sthis.html(output.data);
				
				filter.parents('.block').find('nav').html(output.p);
				if(getAll){
					//$('.main').find('.shuffle_this').parents('.block').find('.widget_buttons_list').html(output.f);
					sthis.parents('.block').removeClass('hidden');
				}
				paginator.find('.page-link').removeClass('active');
				paginator.find('.page-link[data-page="'+page+'"]').addClass('active');
				//console.log(paginator);
				sidebarStickyDetach();
				sidebarSticky();
				sidebarStickyRecalc();
				paginatorElements(paginator);
				if(typeof lazyload == 'function') lazyload(sthis.find(".lazyload, img[data-lazy]"));
			}
			sthis.stop().animate({opacity: 1}, 100);
			/*if(page == 1){
				//changeHash('id', false);
			}	else	{
				//changeHash('id', page);
			}	
			
			if(filterArr.length){
				//changeHash('filter', filterArr.join(','), 'rewrite');
			}	else	{
				//changeHash('filter', false);
			}*/
			
			//console.log(data);
		}
	});
}
function localJsonpCallback(json) {
	//console.log('localJsonpCallback');
	//console.log(json);
}
function paginatorInit(){
	$(".pagination .page-link[data-page]").click(function(){
		paginatorClick(this,$(this).attr("data-page"));
		return false;
	})
	$(".filtered_block .nav-load-more").click(function(){
		var paginator=$(this).parents('.filtered_block').find('.pagination');
		var page=paginator.find(".page-link.active").attr("data-page");
		if(!page) return false;
		paginatorClick(this,++page,1);
		return false;
	});
	$(".filtered_block .page-link[data-pagemove]").click(function(){
		var page=1;
		var paginator=$(this).parents('.filtered_block').find('.pagination');
		var pages=paginator.attr("data-pages");
		var shift=parseInt($(this).attr('data-pagemove'));
		var current=paginator.find(".page-link.active").attr("data-page");
		current=parseInt(current);
		if(shift && current){
			page=current+shift;
			if(page>pages) page=1;
			if(page<1) page=pages;
			paginatorClick(this,page);
			//console.log("page="+page+" pages="+pages+" shift="+shift);
		}
		return false;
	})
}
function paginatorClick(element, page, append){
	append=append?append:0;// MORE and MORE button
	page = parseInt(page);
	if(page > 0){
		var filter = $(element).parents('.filtered_block');
		page = parseInt(page);
		prepareAjax(page, filter,0,0,append);
		//$(document).scrollTop(filter.offset().top);
		//changeHash('id', page);
	}
	return false;
}
function paginatorHorizontal(){
//nav-horiz nav-page-prev
}
function paginatorNestPrev(){// Nest and Prev buttons
	
}

function paginatorElements(paginator){
	var isMobile=$("body").hasClass("ismobile");
	paginator.each(function(){
		var pag=$(this).find(".page-item").not(".dots-item");
		var active=pag.find(".active");
		var actN=1;
		if(active.length){actN=active.data('page')}
		pag.removeClass("hidden");
		$(this).find(".dots-item").remove();
		if(actN>4||isMobile&&actN>1)pag.slice((isMobile?1:3),actN-1).addClass('hidden');
		var from2=actN+2;
		if(actN<4) from2=5
		if(actN<pag.length-5)pag.slice(from2,pag.length-(isMobile?2:3)).addClass('hidden');
		pag.each(function(k,o){
			if(!$(o).is(".hidden")&&$(o).next().is(".hidden")){
				$(o).after('<li class="page-item dots-item"><span class="dots">...</span></li>');
			}
		})
	})
}

function transferFilter(element){
	return false;
	/*if(location.hash != ""){
		$(element).attr('href', $(element).attr('href')+changeHash('pattern', $('.filtered_block').find('.ajaxInfo').attr('data-info')));
	}*/
}
/*
function filterList(){
	if($('.filter_list').length > 0){
		$('.filter_list').find('a').on('click', function(){
			$(this).parents('.block').find('.shuffle_this').children().shuffle();
			return false;
		});
	}
}
*/

/*
function hashInit(){
	var filter = $('.filtered_block');
	var page = getParameterByName('id', location.hash.replace('#', '&'));
	var filters = getParameterByName('filter', location.hash.replace('#', '&'));
	var pattern = getParameterByName('pattern', location.hash.replace('#', '&'));
	//console.log('page:'+page);
	//console.log('filters:'+filters);
	//console.log('pattern:'+pattern);
	var getAll = 1;
	if(filters){
		var filtersArr = filters.split(',') ? filters.split(',') : [];
		if (Array.isArray(filtersArr)){
			$.each(filtersArr, function(index, element){
				filterClickFunc(filter.find('[data-f-method="'+element+'"]'), false);
			});
		}
	}
	if(page || filters || pattern){
		if(pattern){
			$('.ajaxInfo').attr('data-info', pattern);
		}
		prepareAjax(page, filter, pattern, getAll);
		
		changeHash('pattern', false);
		return true;
	}
	return false;
}
*/

/*
function changeHash(key, value, action){
	var hashStr = location.hash.replace('#','');
	var hashArray = hashStr.split('&');
	var done = 0;
	if(key !== undefined && value !== undefined){	
		if (Array.isArray(hashArray)){
			$.each(hashArray, function(index, element){
				if(!element){
					hashArray.splice(index, 1);
					return true;
				}
				element = element.split('=');
				if(Array.isArray(element)){
					$.each( element, function(ind, el){
						if(ind == 0 && key == el){
							if(value === false || value == ''){
								hashArray.splice(index, 1);
							}	else	{
								if(action == 'add' && !~hashArray[index].indexOf(value)){
									hashArray[index] = hashArray[index]+','+value;
								}	else	if (action == 'rewrite'|| !~hashArray[index].indexOf(value)){
									hashArray[index] = key+'='+value;
								}
							}
							done = 1;
							return true;
						}
					});
				}
			});
		}
		if(!done && value !== false){
			hashArray.push(key+'='+value);
			done = 1;
		}
		if(done){
			hashStr = hashArray.join('&');
		}
	}
	if(hashStr != ''){
		var uri = window.location.toString();
		var clean_uri = uri.substring(0, uri.indexOf("#"));
		window.history.replaceState({}, document.title, clean_uri+'#'+hashStr);
		//location.hash = '#'+hashStr;
		return '#'+hashStr;
	}	else	{
		removeHash();
		return '';
	}
}
*/
function filterToggle(){
	var filterbutton=$('.filter_button');
	if(filterbutton.length > 0){
		filterbutton.on('click', function(event){
			var filter = $(this).parents('.filtered_block');
			if($(this).parent().hasClass('active')){
				$(this).parent().toggleClass('active');
			}	else	{
				filter.find('.filter_button').parent().removeClass('active');
				$(this).parent().addClass('active');
			}
			return false;
		});
		$('.filter_block').on('mouseleave',function(){
			if($(this).hasClass('active')){
				$(this).toggleClass('active');
			}
		});
		$('.filter_sort').on('click', function(){
			var sort_array = $(this).data('f-sotr_string').split(',');
			var current = $(this).data('f-sort');
			var me = $(this);
			var filter = $(this).parents('.filtered_block');
			filter.find('.filter_sort').each(function(){
				if($(this) !== me){
					$(this).html($(this).data('f-sotr_string').split(',')[0]).data('f-sort', 0);
					$(this).removeClass('changed');
				}
			});
			if(sort_array){
				current++;
				current = current%sort_array.length;
				$(this).html(sort_array[current]);
				$(this).data('f-sort', current);
				$(this).addClass('changed');
				filter.find('.filter_reset').addClass('show');
				prepareAjax(1, filter);
			}
			return false;
		});
		$('.filter_search').on('change', function(){
			var filter = $(this).parents('.filtered_block');
			$(this).addClass('changed');
			filter.find('.filter_reset').addClass('show');
			prepareAjax(1, filter);
		});
		$('.filter_reset').on('click submit', function(){
			var filter = $(this).parents('.filtered_block');
			filter.find('.filter_sort').each(function(){
				$(this).html($(this).data('f-sotr_string').split(',')[0]).data('f-sort', 0);
				$(this).removeClass('changed');
			});
			filter.find('.filter_button').each(function(){
				$(this).text($(this).data('f-default'));
				$(this).parents('.filter_block').find('.active').removeClass('active');
				$(this).removeClass('changed');
			});
			filter.find('.filter_search').each(function(){
				$(this).val('');
				$(this).removeClass('changed');
			});
			$(this).removeClass('show');
			removeHash();
			prepareAjax(1, filter);
			return false;
		});
	}
}
function filterDeveloper(){
	var devfilter=$('.developer-filter');
	if(devfilter.length){
		devfilter.find('a').on('click', function(){
			var filter = $('.filtered_block');
			$(this).parents('.developer-filter').find('.active').removeClass('active');
			$(this).addClass('active');
			prepareAjax(1, filter);
			$(document).scrollTop(filter.offset().top);
			return false;
		});
	}
}
function filterClickFunc(element, click){
	var dataPref = 'data-f-';
	var method = $(element).attr(dataPref+'method') ? $(element).attr(dataPref+'method') : false;
	var filter = $(element).parents('.filtered_block');
	var filterButton = $(element).parents('.filter_block').find('.filter_button');
	var textButton = filterButton.attr(dataPref+'text') ? filterButton.attr(dataPref+'text') : filterButton.text();
	//console.log(filterButton.text());
	if($(element).attr(dataPref+'replace') == 1 && method != 'reset'){
		filterButton.find('.filter-button-text').text($(element).text());
	}	else if (method == 'reset'){
		filterButton.find('.filter-button-text').text(filterButton.attr(dataPref+'default'));
	}	else	{
		filterButton.find('.filter-button-text').text(filterButton.attr(dataPref+'default')+' '+$(element).text());
	}
	filterButton.addClass('changed');
	$('.filter_reset').addClass('show');
	$(element).parents('ul').find('.active').removeClass('active');
	if(method != 'reset'){
		$(element).addClass('active');
	}
	if(click !== false){
		prepareAjax(1, filter);
	}
	return false;
	
}
function filterClick(){
	$('.filter_list a').on('click', function(){
		$(this).parents('.filter_block').addClass('changed');
		filterClickFunc(this);
		return false;
	});
}
function buttonsRadioReset(){
	//if($('.radio_button>[type="radio"]').length){
		$('.radio_button>[type="radio"]').each(function(){
				if($(this).prop("checked")){
					$(this).parent().addClass('active');
				}	else	{
					$(this).parent().removeClass('active');
				}
		});
	//}
}
function buttonsRadio(){
	//if($('.radio_button>[type="radio"]').length){
		$('.radio_button>[type="radio"]').each(function(){
			if($(this).prop("checked")){
				$(this).parent().addClass('active');
			}	else	{
				$(this).parent().removeClass('active');
			}
			$(this).parent().on('click', function(){
				if(!$(this).hasClass('active')){
					$(this).parents('.buttons-group').find('.active').removeClass('active').find('[type="radio"]').prop("checked", false);
					$(this).addClass('active').find('[type="radio"]').prop("checked", true);
				}
			});
		});
	//}
}
function buttonsCheckbox(){
	//if($('.checkbox_button>[type="checkbox"]').length){
		$('.checkbox_button>[type="checkbox"]').each(function(){
			if($(this).prop("checked")){
				$(this).parent().addClass('active');
			}
			$(this).parent().on('click', function(){
				if(!$(this).hasClass('active')){
					$(this).addClass('active').find('[type="checkbox"]').prop("checked", true);
				}	else	{
					$(this).removeClass('active').find('[type="checkbox"]').prop("checked", false);
				}
			});
		});
	//}
}
/*
function paginationInit(){
	if($('.pagination').length > 0){
		$('.pagination').each(function(index, element){
			paginator = $(element);
			paginator.find('a.page-link').on('click', function(e){
				paginator.find('a.page-link').off('click');
				var current_active = paginator.find('.active');
				if($(this).hasClass('page-next')){
					var next = paginator.find('.active').parent('.page-item').next().find('a.page-link').addClass('active');
					next.addClass('active');
					next.replaceWith('<span class="'+next.attr('class')+'" href="#id='+next.text()+'">'+next.text()+'</span>');
					current_active.removeClass('active');
					paginator.parent().parent().find('.shuffle_this').children().shuffle();
					console.log('You tap on next');
				}	else	{
					current_active.removeClass('active');
					$(this).addClass('active').replaceWith('<span class="'+$(this).attr('class')+'" href="#id='+$(this).text()+'">'+$(this).text()+'</span>');
					paginator.parent().parent().find('.shuffle_this').children().shuffle();
					console.log('You tap on '+$(this).text());
				}
				current_active.replaceWith('<a class="'+current_active.attr('class')+'" href="#id='+current_active.text()+'">'+current_active.text()+'</a>');
				$(document).scrollTop(paginator.parent().parent().offset().top);
				paginationInit();
			});
		});
		
	}
}
*/
var fullscreen = 0;
function fullscreenButton(){
	//if($('.button-fullscreen').length){
		$('.button-fullscreen').on('click', function(){
			var me = $(this);
			if($(this).parents('.block').hasClass('fullscreen')){
				fullscreen = 0;
				$(this).parents('.block').removeClass('fullscreen').find('.button-fullscreen-close').off('click');
				//$(this).html('<span>'+$(this).data('text-full')+'</span>');
				$('body').removeClass('overhidden');
				$(document).off('keyup');
				iframeSize();
			}else{
				fullscreen = 1;
				$(this).parents('.block').addClass('fullscreen').find('.button-fullscreen-close').on('click',function(){
					me.click();
					return false;
				});
				//$(this).html('<span>'+$(this).data('text-min')+'</span>');
				$('body').addClass('overhidden');
				$(document).on('keyup',function(evt) {
					if (evt.keyCode == 27) {
					   me.click();
					}
				});
				iframeSize();
			}
			return false;
		});
	//}
}
function dataShow(){
	if($('[data-show!=""][data-show]').length > 0){
		var blockClass = "show";
		var buttonClass = "active";
		$('[data-show!=""][data-show]').on('click', function(){
			var id = $(this).attr('data-show');
			var block = $('#'+id);
			var me = $(this);
			if(block.length > 0){
				
				if(block.hasClass(blockClass)){
					me.removeClass(buttonClass);
					block.removeClass(blockClass);
				}	else	{
					block.addClass(blockClass);
					me.addClass(buttonClass);
				}
				block.parents().on('mouseleave',function(){
					me.removeClass(buttonClass);
					block.removeClass(blockClass);
				});
			}
		});
	}
}
function showNext(){
	//if($('.show_next').length > 0){
		$('.show_next').on('click', function(){
			$(this).hide().next().show();
			return false;
		});
	//}
}
function menuToggle(){
	var sidebar=$('.sidebar');
	$('.navbar-toggler').on('click',function(){
		//$($(this).data('target')).toggle();
		if(sidebar.hasClass('open')){
			$(this).removeClass('active');
			sidebar.removeClass('open').css('display', '');
			$('.main').removeClass('hidden-md-down');
		}	else	{
			$("html,body").scrollTop(0);
			$(this).addClass('active');
			sidebar.show().addClass('open');
			$('.main').addClass('hidden-md-down');
		}
		return false;
	});
}

function iframeSize(ratio) {
	ratio = ratio ? ratio : (gameRatio?gameRatio:1.3333);
	var pixelRatio = 1;
    /*if (window.devicePixelRatio != undefined) {
        var pixelRatio = window.devicePixelRatio;
    } else {
        var pixelRatio = 1;
    }*/
	var frame = $('#iframe_background').find('iframe');
	var frameContainer=$('#iframe_background');
	if (frame.length > 0) {
		//if(frame.parents('.fullscreen').length){
		var H;
		var W = frameContainer.width();
		if(fullscreen){
			/*if(frame.width() / ratio >= H){
				frame.width(W);//H*ratio
				frame.height(H);
				console.log("iframeSize() - fullscreen 1");
			}	else	{
				frame.height( Math.ceil(W / ratio));
				frame.width(W);
				console.log("iframeSize() - fullscreen 2");
			}*/
			$("#iframe").height("100%");
			H = frameContainer.innerHeight();
			frame.width(W);
			frame.height(H);
		}	else	{
			$("#iframe").height( Math.ceil(W / ratio));
			H = frameContainer.innerHeight();
			frame.width("100%");
			frame.height( Math.ceil(W / ratio));
			//console.log("iframeSize() - ration="+ratio);
		}
	}
}

function loadIframeError(mobile){
	if($('#iframe').length > 0){
		var iframeBlock = $('.iframe-block');
		var iframeBackground = $('.iframe_background');
		iframeBackground.show();
		$.ajax({
			type: 'POST', // oтпрaвляeм в POST фoрмaтe
			url: apiPath + 'post/' + options['context'] + '/' + (mobile ? 'mobile' : 'desktop') + '/game_iframe_error', // путь дo oбрaбoтчикa
			xhrFields: {
				withCredentials: true
			},
			success: function(data){
				if(data){
					iframeBackground.append(data);
					iframeBlock.hide();
					if(typeof lazyload == 'function') lazyload(iframeBackground.find(".lazyload, img[data-lazy]"));
				}
			}
		});
	}
}

/*
function loadIframe() {
	var iframe = $('#iframe');
	var start = $('.btn_startgame');
	var iframe_background = $('#iframe_background');
	var src_des = $(iframe_background).data('d-src');
	var src_mob = $(iframe_background).data('m-src');
	var src_vid = $(iframe_background).data('v-src');
	$(start).on('click', function (e) {
		$('.button-fullscreen').click();
		if($(this).data('mobile')){
			return true;
		}
		e.preventDefault();
		if(!src_des && !src_mob){
			$('.iframe').html("<video autoplay=\"\" onloadstart=\"this.volume=0.1\" style=\"width:100%;\"><source src=\""+src_vid+"\" type=\"video/mp4\"></video>");
			
			$('video').prop('volume', 0.1).on('click', function(){
				if($('#playInCasino').length)$(document).scrollTop($('#playInCasino').offset().top);
			});
			iframeSize();
			return false;
		}
		$(this).fadeOut();
		$.ajax({
			type: 'POST', // oтпрaвляeм в POST фoрмaтe
			url: apiUrl, // путь дo oбрaбoтчикa
			dataType: "json",
			xhrFields: {
				withCredentials: true
			},
			data: {
				src_des: src_des,
				src_mob: src_mob,
				src_vid: src_vid,
				action: 'loadIframe'
				}, // дaнныe для oтпрaвки
			success: function(data){
				console.log(data);
				$('.iframe').html(data['data']);
				
				if(~data['data'].indexOf('id="iframe_game"')){
					iframeSize();
				}
			}
		});
	});
}
*/
var scrollBars = new Object();
function fader(){
	var fader=$('.fader');
	if( fader.length > 0 ){
		var close = 1;
		var hashStr = location.hash.replace('#','');
		var faderoffset = 30;
		if(hashStr && (hashStr.indexOf('=')== -1) && (hashStr.indexOf('&')== -1))
			if($('#'+hashStr).length > 0){
				close = 0;
			}
		fader.each(function(index, element){
			
			if($(element).find('.button-fader').length > 0 && $(element).find('.fader-wrapper').length > 0){
				if($(element).find('.fader-wrapper').height()<1){
					$(element).hide();
				}
				$(element).find('.button-fader').on('click', function(){
					if($(this).hasClass('link')){
						
					} else if(!$(element).hasClass('fader_in_progress')){
						if(!$(element).attr('data-fader-start')){
							$(element).attr('data-fader-start', $(element).height());
						}
						$(element).addClass('fader_in_progress');
						//sidebarStickyDetach();
						var height_start = $(element).attr('data-fader-start');
						var height_end = ($(element).attr('data-fader') ? $(element).attr('data-fader') : $(element).find('.fader-wrapper').outerHeight());
						if($(element).hasClass("fader_nop")){
							height_end -= faderoffset*2; //магическое число блока скрывальщика с фейдом
						}
						var speed = 500;
						//Закрывание
						if($(element).hasClass('fader_opened')){
							$(element).stop();
							if($(element).hasClass('fader_foldable')){
								$(element).stop().animate({height:height_start},speed, 'swing', function(){
									$(this).removeClass('fader_opened');
									if($(this).find('.button-fader').attr('data-more') !== undefined){
										$(this).find('.button-fader').html($(this).find('.button-fader').attr('data-more'));
									}	else	{
										$(this).find('.button-fader').html($(this).find('.button-fader').html().replace(/less/ig, 'More'));
									}
									/*
if(scrollBars[$(element).find('.scrollable').attr('id')] !== undefined){
										scrollBars[$(element).find('.scrollable').attr('id')].update();
									}
*/
									sidebarStickyRecalc();
									$(this).removeClass('fader_in_progress');
								});
								return false;
							}
						}	else	{
							//Открывание
							$(element).stop().animate({height:height_end},speed, 'swing', function(){
								$(this).addClass('fader_opened');
								$(this).attr('data-fader-start', height_start);
								if($(element).hasClass('fader_foldable')){
									if($(this).find('.button-fader').attr('data-less') !== undefined){
										$(this).find('.button-fader').html($(this).find('.button-fader').attr('data-less'));
									}	else	{
										$(this).find('.button-fader').html($(this).find('.button-fader').html().replace(/more/ig, 'Less'));
									}
								}
								/*
if($(element).hasClass('add_scroll')){
									if(scrollBars[$(element).find('.scrollable').attr('id')] !== undefined){
										scrollBars[$(element).find('.scrollable').attr('id')].update();
									}	else	{
										scrollBars[$(element).find('.scrollable').attr('id')] = new PerfectScrollbar('#'+$(element).find('.scrollable').attr('id'));
									}
								}
*/
								sidebarStickyRecalc();
								$(this).removeClass('fader_in_progress');
							});
							
							return false;
						}
					}
				});
				if($(element).attr('data-fadersidebar') == 1 && $('.sidebar_wrapper').length > 0 && $('.main_wrapper').length > 0){
					var addHeight = ($('.sidebar_wrapper').height()+parseFloat($('.sidebar_wrapper').children().last().css('margin-bottom'))-$('.main_wrapper').height()-$(element).data('fader-start')+$(element).height());
					$('.main_wrapper').find('.fader');
					//console.log(addHeight);
					if(addHeight > 0){
						//console.log($(element).attr('data-fader-start'));
						$(element).attr('data-fader-start', (parseInt($(element).attr('data-fader-start'))+parseInt(addHeight)));
					}
				}
				/*
if($(element).attr('data-fadertocheight') == 1){
					if($(element).height() < $(element).find('.table_of_contents').height()+faderoffset*1.5)
						$(element).height($(element).find('.table_of_contents').height()+faderoffset*1.5);
				}
*/
				if($(element).attr('data-faderhide') == 1){
					if($(element).attr('data-fader-start') > ($(element).find('.fader-wrapper').height()-faderoffset*2)){
						$(element).addClass('fader_after-none');
						if($(element).attr('data-faderautoheight') == 1){
							$(element).addClass('fader_autoheight');
						}
					}
				}
			}
			if(close && $(element).hasClass('fader_opened')){
				$(element).find('.button-fader').click();
			}
		})
	}
}
function reportForm(){
		$('#report_form').on( "submit", function(event){
			event.preventDefault();
			var params = $(this).serializeFormJSON();
			params['screen'] = $(window).width()+'x'+$(window).height();
			params['user_agent'] = navigator.userAgent;
			$.ajax({
				type: 'POST', // oтпрaвляeм в POST фoрмaтe
				url: apiUrl, // путь дo oбрaбoтчикa
				dataType: "json",
				xhrFields: {
					withCredentials: true
				},
				data: params, // дaнныe для oтпрaвки
				success: function(data){
					if(data['error'] == "ok"){
						$('#report_form')[0].reset();
						buttonsRadioReset();
						closeAllModals();
					}
				}
			});
			return false;
		});
}
function feedback(){
	if($('#feedback_form').length > 0){
		$('#feedback_form').on( "submit", function(event){
			event.preventDefault();
			var params = $(this).serializeFormJSON();
			params['screen'] = $(window).width()+'x'+$(window).height();
			params['user_agent'] = navigator.userAgent;
			//console.log(params);
			$.ajax({
				type: 'POST', // oтпрaвляeм в POST фoрмaтe
				url: apiUrl, // путь дo oбрaбoтчикa
				dataType: "json",
				xhrFields: {
					withCredentials: true
				},
				data: params, // дaнныe для oтпрaвки
				success: function(data){
					//console.log(data);
					//console.log(data == 'ok');
					if(data['error'] == "ok"){
						$('#feedback_form')[0].reset();
						closeAllModals();
					}
				}
			});
			return false;
		});
	}
}
function rating(){
	if($('li.star > a').length > 0 && $('li.star > a').parents('ul').find('.current-rating').length > 0){
		var rating_timeout = 0;
	   
		$('li.star > a').on("click", function(){
			if(rating_timeout == 0){
				// мелкая защита от кликанья
				rating_timeout = 1;
				setTimeout(function(){rating_timeout = 0}, 3000);
				// берем параметры прямо из ссылки
				var href = $(this).attr('href');
				var rating_id = ($(this).attr('data-id'));
				var rating_vote = ($(this).attr('data-vote'));
				var rating_type = ($(this).attr('data-type'));
				var me = $(this);
				if(rating_id && rating_vote){
					//готовим запрос на страницу ajax
					
					$.ajax({
						type: 'GET', // oтпрaвляeм в POST фoрмaтe
						url: apiUrl, // путь дo oбрaбoтчикa
						dataType: "json",
						xhrFields: {
							withCredentials: true
						},
						data: {
							action: "voting", rating_id: rating_id, rating_type: rating_type, rating_vote: rating_vote, ajax: "1"
							}, // дaнныe для oтпрaвки
						success: function(data){
							//console.log(data);
							if(data > 0){
								me.parents('ul').find('.current-rating').css('width', (data*2*10)+"%"); // меняем текущие звезды в процентах
							}
						}
					});
				}
			}
			return false;
			
		});
	}	else	{
		return false;
	}
}
function animateLogo(){
	var logoAnim = 0;
	if($('.clever').length > 0){
		$(".clever").animateSprite({
			fps: 2,
			animations: {
				start: [0, 1, 2, 3, 4]
			},
			loop: false,
			complete: function(){
				// use complete only when you set animations with 'loop: false'
			}
		});
	}
	$('.nav-brand').mouseover(function(){
		$(".clever").animateSprite('restart');
	});	
	
}
var animateTextInt;
var loaderAnim = 0;
function animateLoader(){
	var fpsImg = 2;
	if($('.loader_img').length > 0){
		$('.loader_img').animateSprite({
			fps: fpsImg,
			animations: {
				start: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
			},
			loop: true
		});
		loaderAnim = 1;
	}
	var timerText = 1000 / fpsImg;
	var textLoading = Array('.','..','...');
	var textNum = 0;
	if($('.loader_progress').length > 0){
		animateTextInt = setInterval(function(){
			$('.loader_progress').text(textLoading[textNum]);
			textNum++;
			textNum = textNum%textLoading.length;
		}, timerText);
	}
}
function stopAnimateLoader(){
	if(loaderAnim == 1){
		$(".loader_img").animateSprite('stop');
	}
	clearInterval(animateTextInt);
	
}
/*
function playSound(){
	var sample = document.getElementById("audio");
	sample.volume = 0.5;
	$('a').on('click', function(){
		sample.play();
	});
}
*/
/*
function faderHash(){
	$(window).on('hashchange', function (e) {
		if($('.fader').find(location.hash).length > 0){
			var block = $('.fader').find(location.hash).parents('.fader');
			if(!block.hasClass('fader_opened')){
				block.find('.button-fader').click();
				block.scrollTop(0);
				$(window).scrollTop(block.find(location.hash).offset().top);
			}
		}
	});
}
*/

function tableOfContents(){
	$('.pageNav .anchor-link').on('click',function(){
		var hash = $(this).attr('href').split("#")[1];
		var target=$('.main_wrapper').find('#'+hash);
		var block = target.parent();//target.nextUntil("h2,h3,h4,div,a","p, ul, ol");//next('').next('p');
/*
		$("html, body").stop().animate({scrollTop:target.offset().top-80}, 333, 'swing', function() { 
			block.css('color','#f2f2f2');
		});
*/
		block.css('color','#f2f2f2');
		setTimeout(function(){
				block.attr('style','');
			}, 10000);
	});
}

function gameAnchor(){
	if($('#iframe').length > 0 && location.hash.replace('#','') == ''){
		$(window).scrollTop($('#iframe').parents('.block').offset().top-15);
	}
}
function randomSlot(){// DELETE? DEPRECATED?
/*	if($('.button-random').length){
		$('.button-random').on('click', function(){
			var lang = $(this).data('lang') ? $(this).data('lang') : 'en-en';
			var me = $(this);
			$.ajax({
				type: 'GET', // oтпрaвляeм в POST фoрмaтe
				url: apiUrl, // путь дo oбрaбoтчикa
				dataType: "json",
				xhrFields: {
					withCredentials: true
				},
				data: {
					action: 'randomSlot',
					lkey: lang
					}, // дaнныe для oтпрaвки
				success: function(data){
					var output = data;
					if(output.url != undefined){
						me.attr('href', output.url);
						window.location = output.url;
					}
				}
			});
			return false;
		});
	}*/
}
/*function mainMenu(){// DELETE? DEPRECATED?
	var pageType = $('.content_wrapper').attr('data-pagetype') ? $('.content_wrapper').attr('data-pagetype') : '0';
	if(pageType > 0){
		$('#navbarNav').find('[data-pagetype = '+pageType+']').addClass('active');
	}
}*/
/*function bunnerButton(){
	if($('.banner_button').length > 0){
		$('.banner_button').on('click', function(){
			if($(this).hasClass('opened')){
				return true;
			}	else	{
				var me = $(this);
				$(this).find('.banner_button-image').slideDown('fast', function(){
					me.addClass('opened');
				});
				$(this).find('.banner_button-text').text($(this).attr('data-banner-button-end'));
				return false;
			}
		});
	}
}*/
function countryInfo(){
	var me = $('.country-info');
	if(me.length > 0){
		me.find('.button-open').on('click', function(){
			if(me.hasClass('opened')){
				return false;
			}	else	{
				me.find('[data-open="1"]').slideDown('500', function(){
					me.addClass('opened');
					sidebarStickyRecalc();
				});
				return false;
			}
		});
		me.find('.button-close').on('click', function(){
			if(!me.hasClass('opened')){
				return false;
			}	else	{
				me.find('[data-open="1"]').slideUp('500', function(){
					me.removeClass('opened');
					sidebarStickyRecalc();
				});
				return false;
			}
		});
	}
}
/*
function widgetControlPanel(){
	if($('.widget-control-panel').length > 0 && $('.widget-control-button').length > 0){
		$('.widget-control-button').on('click', function(){
			if(!$(this).hasClass('active')){
				$(this).parents('.sidebar_widget').find('.widget-top-list').removeClass($(this).data('widget-control-remove')).addClass($(this).data('widget-control'));
				$(this).parent().find('.active').removeClass('active');
				$(this).addClass('active');
			}
			return false;
		});
	}
}
*/

/*
$(window).load(function() {
	fader();
});
*/
var sidebar, sidebarInit = 0;
function sidebarSticky(){
	//$('.sidebar_wrapper').stickme();
	if(!sidebarInit){
		sidebarInit = 1;
		sidebar = new StickySidebar('.sidebar_wrapper', {
			topSpacing: 20,
			bottomSpacing: 20,
			containerSelector: '.sidebar',
			innerWrapperSelector: '.sidebar_inner'
		});
	}
}
function sidebarStickyDetach(){
	//$('.sidebar_wrapper').trigger("stickme:detach");
	if(sidebarInit){
		sidebarInit = 0;
		sidebar.destroy();
		//console.log('destroyed');
	}
	
	
}
function sidebarStickyRecalc(){
	//sidebarSticky();
	//$('.sidebar_wrapper').trigger("stickme:recalc");
	if(sidebarInit){
	sidebar.updateSticky();
	}
//	console.log('recalc - '+sidebarInit);
}
function sideBarTransferInit(){
	if($(window).width() >= 942){
		//sideBarTransfer(0);
		sidebarSticky();
		sidebarStickyRecalc();
	}	else	{
		//sideBarTransfer(1);
		sidebarStickyDetach();
	}
	//console.log("sidebarInit - "+$(window).width());
	return true;
}
/*
function sideBarTransfer(direction){
	if(direction == '1'){
		$('.sidebar_end').append($('.sidebar_wrapper').find('.movable').removeClass('hidden-md-down'));
	}	else	{
		if($('.sidebar_wrapper').find('.inner-wrapper-sticky').length > 0)	{
			$('.sidebar_wrapper').find('.inner-wrapper-sticky').append($('.sidebar_end').find('.movable'));
		}	else	{
			$('.sidebar_wrapper').append($('.sidebar_end').find('.movable'));
		}
	}
}
*/
function cookieAgreement(){
	if($('[data-cookie="agree"]').length > 0){
		$('[data-cookie="agree"]').on('click', function(){
			Cookies.set('cookie', '1', { expires: 365, path: '/', domain: '.'+location.hostname });
			$(this).parents('.cookie_block').hide();
		});
	}
}
function sitemap(){
	if($('.sitemap').length > 0){
		var i = 0;
		$('.sitemap').find('a').attr('class', '');
		//$('.sitemap ul>li>ul').parent().addClass('my-2');
		/*
$('.sitemap ul>li>ul').each(function(){
			var widthList = $(this).width() / 100;
			var maxWidth = 0; //maxWidth in %;
			$(this).find('li').each(function(){
				let elemWidth = ($(this).find('a, span').width()+10)/widthList;
				maxWidth = elemWidth > maxWidth ? elemWidth : maxWidth;
			});
			if(maxWidth < 25){
				$(this).addClass('column-count-4');
			}	else if(maxWidth < 33){
				$(this).addClass('column-count-3');
			}	else if(maxWidth < 50){
				$(this).addClass('column-count-2');
			}
		});
*/
/*

		$('.sitemap').find('ul').each(function(){
			if(i != 0){
				//$(this).addClass('collapse').parent().addClass('button_collapse');
			}	else	{
				i++;
			}
			if($(this).find('ul').length > 0){
					
			}	else	{
				$(this).addClass('column-count-4').addClass('sitemap-last-level');
			}
		});
*/
		/*
$('.sitemap').find('.button_collapse').on('click',function(e){
			if (e.target !== this)
				return;
			if($(this).hasClass('show')){
				$(this).removeClass('show').children('.collapse').removeClass('show');
			}	else	{
				$(this).addClass('show').children('.collapse').addClass('show');
			}
			
		});
*/
		
	}
}
function tableWrap(){
	var tables=$('.text-block table');
	if(tables.length > 0){
		tables.wrap('<div class="overflow_auto"></div>')
	}
}
function sidebarCountryFilter(){
	if($('#sidebar-coutry-filter').length > 0){

 		$('#sidebar-coutry-list').liveFilter('#sidebar-coutry-filter', 'li', {
			filterChildSelector: 'a',
			after: function() {
				
				if ( $('#sidebar-coutry-filter').val() == '' && $('#sidebar-coutry-list').hasClass('hide-list') ) {
					$('#sidebar-coutry-list li').hide();
				}
				
			}
		});
	}
}

function screenLightbox(){
	var gallery=$('.screenshots_gallery a');
	if(gallery.length > 0){
		gallery.simpleLightbox();
		gallery.click(function(){
		if($(".slbContent .button-real-play").length==0 && $(".button-real-play").length){
			$(".slbContent").append($(".button-real-play").clone());
		}
		})
	}
	
	var video = $(".video-screen");
	if(video.length){
		$(".js-videoplay").click(function(){
			video.find('a').click();
		})
	}

}

function fixedHeader(){
	var header=$(".fixedheader");
/*
	if($(window).width()<943 && $(window).width()<$(window).height()/.8) {
		header.parent().height(header.height());
		console.log('set header Height');
	}
*/
	//header.width(header.parent().width());
	if($(window).width()<943 && $(window).width()<$(window).height()/.8){
		header.addClass('fixed');//.stop().css('top','-50px').animate({top:0},250);
	}else{
		header.removeClass('fixed');
	}
/*
	$(window).scroll(function(){
		var pl=$(window).width()>$(window).height()/.8;
		if($(window).width()>=957||pl) { header.parent().css("height","auto"); return; }
		//if($(window).scrollTop()>150){
			//console.log('move');

			if(!header.hasClass('fixed')){
				header.addClass('fixed').stop().css('top','0px');
				//header.addClass('fixed').stop().css('top','-50px').animate({top:0},300);
			}

		}else{
			header.removeClass('fixed');
			//header.parent().height("auto");
		}

	});
*/
	$(window).resize(function(){
		var pl=$(window).width()>$(window).height()/.8;
		//header.width(header.parent().width());
		
		if($(window).width()>=943||pl){
				header.removeClass('fixed');
				header.parent().css("height","auto");
				return;
			}
		header.parent().height(header.height());
		if(header.hasClass('fixed')){
			header.parent().height(header.removeClass('fixed').height());
			header.addClass('fixed');
		}else{
			header.parent().height(header.height());
			header.addClass('fixed');
		}
		
	});
}

function share() {
    var shareBtn=$('.s-share .s-share__link, .share-this');
    shareBtn.each(function(k,o) { 
        var url=$(this).attr('data-url');
        url=url.replace("{url}",escape(location.href)).replace("{title}",$("title").text());
        $(this).attr('data-url',url);
    });
	  
    shareBtn.on('click', function() { 
        var url=$(this).attr('data-url');
        var w=500,h=400;
        var left = (screen.width/2)-(w/2);
		var top = (screen.height/2)-(h/2);
        window.open(url,'','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
        return false;
    });
}


function hints(){
	$(".hint-opener").on("mouseover click",function(e){
		var t=$(this).parent().parent().find('#'+$(this).attr("data-hint"));
		t.show();
		if(e.type=="click" && t.is(":visible")){
			t.hide();
		}
		$(this).parent().one("mouseleave",function(){
			t.hide();
		});
		return false;
	});
}


/*function hideContentText() {
    if ($(window).width() <= 560) {
    	var headers = $('.text-block h2, .text-block h3, .text-block h4, .text-block h5,.text-block h6, .sidebar_widget .widget-title');

        headers.each(function() {
        	if (!$(this).hasClass('no-wrap')) {
                $(this).nextUntil(':header, .sidebar_widget').wrapAll('<div class="hide-text-mob"></div>');
            }
		});
        $('.hide-text-mob').each(function() {
            if ($(this).children('div').length > 0 && !$(this).parents('.sidebar_widget')) {
                $(this).removeClass('hide-text-mob');
			} else {
            	$(this).after('<div class="border-block-mobile"></div>').prev().addClass('block-header');
			}
		});
        $('.block-header').on('click', function() {
        	$(this).toggleClass('opened').next('.hide-text-mob').stop().slideToggle();
		});
    }
}*/

/*
function hideBigContentParts(maxHeight,selector,inner){
	if(!maxHeight) maxHeight=380;
	if(!selector) selector='.text-block:not(.donthide) h2, .text-block:not(.donthide) h3, .text-block:not(.donthide) h4, .text-block:not(.donthide) h5';
	var headers = $(selector);
	headers.each(function() {
        if(inner){
			$(this).wrapInner('<div class="content-part tohide"></div>');
	    }else{
		    $(this).nextUntil('h2,h3,h4,h5,h6,div:not(.overflow_auto)').wrapAll('<div class="content-part tohide"></div>');
	    }
	});
	$("div.content-part.tohide").each(function(){
		$(this).removeClass('tohide');
		if($(this).height()>=maxHeight){
			$(this).css("max-height",maxHeight+"px").addClass("fader-text").append('<div class="button button-center button-grey with-arrow open-content-part"><span>'+translate.nav_show_more+'</span><svg width="9px" height="5px"><use xlink:href="#svgArrow"></use></svg></div>');
		} else{
			
			$(this).removeClass("fader-text");
		}
	});
	$(".open-content-part").click(function(){
		$(this).parent().removeClass("fader-text").css("max-height","none");
		$(this).remove();
	})
	return $(".content-part");
}
*/



function showHidden(){
	//$(".show-hidden").each(function(){
		//if($(this).parent().parent().find($(this).attr("data-toshow")+".hidden").children().length==0) $(this).hide();
		//console.log();
	//})
	$(".show-hidden").click(function(){
		$(this).parent().parent().find($(this).attr("data-toshow")+".hidden").removeClass("hidden").addClass("was-hidden");
		$(this).addClass('hidden').parent().find(".hide-hidden").removeClass('hidden');
		sidebarStickyRecalc();
		return false;
	});
	$(".hide-hidden").click(function(){
		$(this).parent().parent().find($(this).attr("data-toshow")).addClass("hidden").removeClass("was-hidden");
		$(this).addClass('hidden').parent().find(".show-hidden").removeClass('hidden');
		sidebarStickyRecalc();
		return false;
	});
}

function Collapser(){
	$(".collapser").each(function(k,o){
		var item=$(o);
		//console.log(k);
		item.find(".collapse-button").click(function(){
			if(item.hasClass("opened")){
				item.removeClass("opened");
			}else{
				item.addClass("opened");
			}
			sidebarStickyRecalc();
			return false;
		});
	})
}

function topMenu(){
/*	var header=$(".header");
	$("#navbarNav .navbar-nav>.nav-item").hover(
	  function() {
	    header.addClass("darkcover");
	  },function() {
	    header.removeClass("darkcover");
	  });
*/
}

/*
function footerMenu(){
	$(".footer-menu-item .close").click(function(){
		$(this).parent().parent().find(".footer-links-list").removeClass('hidden-md-down');
	})
}
*/


function slider(target){
	var active=0;
	var r=$(target);
	if(!r.length) return;
	var slides=r.find(".slide"),
	c=slides.length,
	pag=r.find(".slider-pag");
	slides.removeClass("active").filter(":eq("+active+")").addClass("active");
	if(c==1) return;
	for(i=0;i<c;i++){
		pag.append("<span data-n='"+i+"'></span>");
	}
	pag.find("span:eq("+active+")").addClass("active");
	pag.find("span").click(function(e){
		r.find("span").removeClass("active");
		$(this).addClass("active");
		active=$(this).attr("data-n");
		slides.removeClass("active");
		slides.filter(":eq("+active+")").addClass("active");
		//console.log(active);
		e.preventDefault();
		return false;
	});
	r.find(".slider-nav").click(function(){
		sliderMove(target,$(this).attr("data-d")=="-"?-1:1);
		//clearInterval(mainSliderInterval);
		//mainSliderInterval=setInterval(mainSliderChange,4400);
	});	
}
function sliderMove(target,dir){
	var s=$(target).find(".slider-pag span");
	var a=s.filter(".active").attr("data-n");
	dir?a++:a--;
	a=a>=s.length?0:a;
	a=a<0?(s.length-1):a;
	s.filter(":eq("+a+")").click();
}

function scrolling(){
	var isMobile=$("body").hasClass("ismobile");
	$(".scrolling").each(function(k,o){
		var s=$(this),w=0;
		s.children().each(function(){
			w+=$(this).outerWidth(true);
		});
		if(w<=s.parent().innerWidth()) return;
		s.addClass("col").wrap('<div class="row scrol-wrap"></div>');
		if(!isMobile){
			var p=s.parent();
			p.append('<div class="scrol-contr"><span data-dir="-1"><svg class="icon rotate90" width="10px" height="5px"><use xlink:href="#svgArrow"></use></svg></span><span data-dir="+1"><svg class="icon rotate-90" width="10px" height="5px"><use xlink:href="#svgArrow"></use></svg></span></div>');
			p.find(".scrol-contr span").click(function(){
				var scr=s.scrollLeft();
				var d=parseInt($(this).attr("data-dir"));
				s.stop().animate({scrollLeft:scr+d*s.width()/3},300);
				//s.scrollLeft(scr+d*s.width()/3);
				return false;
			})
		}
	})
}

function anchorSmoothMove(){
	$(".anchor-link").click(function(e){
		var a=$(this).attr('href');
		if(a && a.indexOf("#")==0){
			if($(a).length){
				$("html, body").stop().animate({scrollTop:$(a).offset().top-$(window).height()/20}, 300, 'swing', function(){ });
			}
			e.preventDefault();
			
		}
	});
}

function moveTopGamesMenu(){
	var m=$(".mobile-vgames ul"), w=m.width(),a=m.find(".active");
	if(!a.length) return;
	var c=a.position().left+a.width()/2;
	if(c>w/2){
		m.animate({scrollLeft:c-w/2},400);
	}
}

function toggleOnClick(button,target,className){
	if(!className) className='opened';
	$(button).click(function(){
		$(target).toggleClass(className);
	})
}


isMobile=false;
$().ready(function(){
	isMobile=$("body").hasClass("ismobile");
	
	
	//if(!$('body').hasClass('ru') ||  $(window).width()>560) rainbowTitle(); // разноцветные тайтлы
	//rainbowLinks(); //разноцветные ссылки
	//paginatorClick(); // инициализация кликов по пагинатору
	
	//fader(); // кнопка скрытия текста ?????? Для чего это было??
	
	//filterDeveloper(); // фильтр в сайдбаре со скроллбаром
	
	
	
	//randomSlot(); // переход на рандомный слот
	menuToggle(); //раскрытие меню в мобильном варианте
	//mainMenu(); // подсветка главного меню
	//filterList();
	//paginationInit();
	
	 // форма обратной связи
	
	tableWrap();
	
	//bunnerButton(); //кнопка баннер в сайдбаре
	
	//loadIframe(); // загрузить iframe по клику
	//animateLoader();
	
	
	//animateLogo(); // анимация логотипа
	//hashInit(); // вытащить фильтры из hash
	//playSound(); // звук по клику по <a>
	
	
	 
	
	//sitemap();
	
	
	
/*
	if ($(window).width() <= 720) {
		hideBigContentParts(220);//Скрываем большие куски контента
		hideBigContentParts(180,".text-block h1");//Скрываем контент в начале страницы
	} else {
		hideBigContentParts();//Скрываем большие куски контента
	}
*/
	
	//hideBigContentParts("150",".faq-page .answer",true);
	
	showHidden();
	
	//gameAnchor();// переход сразу на игру
	//topMenu(); Затемнение фона при наведении на пункты меню
	
	
/*
	if(location.hash=='#games'){
		if($("#games-first").length){
				$("html,body").scrollTop($("#games-first").offset().top);
			}else{
				if($("#games").length) $("html,body").scrollTop($("#games").offset().top);
			}
	}
*/
	
	setTimeout(delayedStart, 30);
	setTimeout(delayedStart2, 60);
//	console.log("ready");
});

function delayedStart(){// Start function after timeout
	
	
	paginatorInit();
	
	filterToggle(); // выпадающие фильтры
	filterClick(); // клик по кнопкам фильтров над списками
	
	fullscreenButton(); // фулскрин режим для iframe
	
	sidebarCountryFilter();
	countryInfo(); //сайдбар виджет информации страны
	tableOfContents(); // клик и подсвечивание заголовка, клик по содержанию с разворачиванием нижнего текста
	
	paginatorElements($(".pagination"));
	
	buttonsRadio(); // переключение стилизованых radio buttons 
	buttonsCheckbox();
	showNext(); // показывает следующий элемент и скрывает кнопку
	
	
	
	
	if(isMobile) {
		var block=$(".mobile-vgames"),wwidth=$(window).width()
		//$(".fixedheader").append(block);
		if(block.find(".active").length){
			var left=block.find(".active").offset().left;
			if(left>wwidth/2){
				left=left-wwidth/2.1;
				block.find("ul").scrollLeft(left);
			}
		}
		//console.log('move!');
		fixedHeader();
	}
	
	
	feedback();
	reportForm();
	modalButtons(); // инициалиция модальных окон по клику
	screenLightbox();
	
	
	dataShow();
	
	$(".slider").each(function(){slider(this)});
	
	
	
	Collapser();
	hints();
	
	scrolling();
	anchorSmoothMove();
	
	
	
	if(isMobile) toggleOnClick(".langChanger .current",".langChanger",'drop');
	
	//footerMenu();
	
	//sidebarStickyRecalc();
}

function delayedStart2(){// Start function after timeout
	if(!isMobile){
		sideBarTransferInit();
	}
	//sidebarSticky();
	
	gameLoad();
	
	rating(); // рейтинг AJAX
	scrollToTop();
	cookieAgreement();
	
	share();
}

$(window).resize(function(){
	sideBarTransferInit();
});
