$().ready(function(){
	var ul=$(".mobile-vgames ul"),
	w=ul.width(),
	sum=0;
	$('.mobile-vgames li').each( function(){ sum += $(this).width(); });
	var t=setInterval(function(){
		var s=ul.scrollLeft();
		if(sum-w>s){
			s=s+20;
			//console.log('+50');
		} else {
			clearInterval(t);
			s=0;
		}
		ul.animate({scrollLeft:s});
	}, 7000);
	ul.mousedown(function(){
		clearInterval(t);
	})
});
