(function() {
	if(/(android)/i.test(navigator.userAgent)){
		var andr=$("#andrdownload"),pos = window.scrollY, ticking = false;
		doScroll(pos);
		var disabled=false,timer=0;
		function doScroll(scroll_pos) {
			if(disabled) return;
			var visible=andr.hasClass('visible');
			if(scroll_pos>250){
				if(!visible)andr.addClass('visible');
				if(!timer)setTimeout(function(){andr.remove()}, 25000);
			}else{
				if(visible)andr.removeClass('visible');
			}
		}
		
		window.addEventListener('scroll', function(e) {
		  pos = window.scrollY;
		  if (!ticking) {
		    window.requestAnimationFrame(function() {
		      doScroll(pos);
		      ticking = false;
		    });
		    ticking = true;
		  }
		});
	}
})();

